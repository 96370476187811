import React from 'react'
import { Head, withSiteData, withRouteData } from 'react-static'
import Start from './../componets/Startanimation'
import ContextProvider from './../componets/ContextProvider'
import BandMemberComponete from './../componets/BandMember'
import { withController } from 'react-scroll-parallax'
import PropTypes from 'prop-types'
import metaHeader from './../componets/MetaHeader'

class RodClass extends React.Component {
  render() {
    return <BandMemberComponete
      parallaxController={this.props.parallaxController}
      bandmemberData={this.props.rodData}
    />
  }
}
RodClass.propTypes = {
  parallaxController: PropTypes.object,
  rodData: PropTypes.object
}

export const Rod = withRouteData(withSiteData(withController(RodClass)))

const combinedRod = ({ rodData }) => {
  const sameAs = []
  if (rodData.facebook) {
    sameAs.push(rodData.facebook)
  }
  if (rodData.instagram) {
    sameAs.push(rodData.instagram)
  }
  const data = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: rodData.name,
    alternateName: rodData.alternateName,
    description: rodData.text,
    url: rodData.canonical,
    sameAs
  }
  const script = JSON.stringify(data)
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(rodData)}
        <script type="application/ld+json">{script}</script>
      </Head>
      <Start/>
      <Rod/>
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(combinedRod)
