import React from 'react'
import { Head, withSiteData, withRouteData } from 'react-static'
import Start from './../componets/Startanimation'
import ContextProvider from './../componets/ContextProvider'
import BandMemberComponete from './../componets/BandMember'
import { withController } from 'react-scroll-parallax'
import PropTypes from 'prop-types'
import metaHeader from './../componets/MetaHeader'

class BastiClass extends React.Component {
  render() {
    return <BandMemberComponete
      parallaxController={this.props.parallaxController}
      bandmemberData={this.props.bastiData}
    />
  }
}
BastiClass.propTypes = {
  parallaxController: PropTypes.object,
  bastiData: PropTypes.object
}

export const Basti = withRouteData(withSiteData(withController(BastiClass)))

const combinedBasti = ({ bastiData }) => {
  const sameAs = []
  if (bastiData.facebook) {
    sameAs.push(bastiData.facebook)
  }
  if (bastiData.instagram) {
    sameAs.push(bastiData.instagram)
  }
  const data = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: bastiData.name,
    alternateName: bastiData.alternateName,
    description: bastiData.text,
    url: bastiData.canonical,
    sameAs
  }
  const script = JSON.stringify(data)
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(bastiData)}
        <script type="application/ld+json">{script}</script>
      </Head>
      <Start/>
      <Basti/>
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(combinedBasti)
