/* eslint-disable camelcase */
import React from 'react'
import { Head, withRouteData, withSiteData } from 'react-static'
import Start from './../componets/Startanimation'
import './../styles/pages/links.scss'
import PropTypes from 'prop-types'
import ContextProvider from './../componets/ContextProvider'
import metaHeader from './../componets/MetaHeader'
import fileIcon from './../icons/file.svg'
import imgIcon from './../icons/imgIcon.png'
import downloadIcon from './../icons/download.svg'
import arrowIcon from './../icons/arrow.svg'
import arrow2Icon from './../icons/arrow2.svg'
import facebookIcon from './../icons/facebook.svg'
import instagramIcon from './../icons/instagram.svg'
import sclIcon from './../icons/logo_sw.svg'
import soundcloudIcon from './../icons/soundcloud.svg'
import spotifyIcon from './../icons/spotify.svg'
import youtubeIcon from './../icons/youtube.svg'
import ReactMarkdown from 'react-markdown'

const getIconImag = (links) => {
  let icon
  switch (links.Icon) {
    case 'file':
      icon = fileIcon
      break
    case 'image':
      icon = imgIcon
      break
    case 'download':
      icon = downloadIcon
      break
    case 'arrow':
      icon = arrowIcon
      break
    case 'arrow2':
      icon = arrow2Icon
      break
    case 'facebook':
      icon = facebookIcon
      break
    case 'instagram':
      icon = instagramIcon
      break
    case 'stonecoldlove':
      icon = sclIcon
      break
    case 'soundcloud':
      icon = soundcloudIcon
      break
    case 'spotify':
      icon = spotifyIcon
      break
    case 'youtube':
      icon = youtubeIcon
      break
    default:
      icon = sclIcon
  }
  return <img src={icon} alt={`${links.Icon}fileIcon`} className="linksBoard__picture"/>
}
const LinksClass = ({ linksData }) => {
  return <div className="linksSection pageWrapper" >
    <h1 className="text-center">{linksData.title}</h1>
    <div className="linksBoard">
      {linksData.text !== '' &&
      <p><ReactMarkdown>{linksData.text}</ReactMarkdown></p>}
      <div className="linksBoard__profiles">
        {
          linksData.Links.map((links, i) => {
            return <a
              href={links.url}
              target="_blank"
              key={`links${i}`}
              className="linksBoard__profile"
              rel="noreferrer">
              {getIconImag(links)}
              <span className="linksBoard__name">{links.text}</span>
            </a>
          })
        }
      </div>
    </div>
  </div>
}
LinksClass.propTypes = {
  linksData: PropTypes.object
}

export const Links = withRouteData(withSiteData(LinksClass))
const combinedLinks = ({ linksData }) => {
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(linksData)}
      </Head>
      <Start />
      <Links />
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(combinedLinks)
