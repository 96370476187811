// import React, { Component } from 'react'
// import './app.scss'
// import firebase from 'firebase/app'
// import 'firebase/firestore'
// import logo from './scl-mund.svg'

// class App extends Component {
//   constructor(props) {
//     super(props)
//     // Your web app's Firebase configuration
//     const firebaseConfig = {
//       apiKey: 'AIzaSyDyh-vZyOzF3zRIgPzgZjDLmcFEqNt8Gfs',
//       authDomain: 'stonecoldlove-homepage.firebaseapp.com',
//       databaseURL: 'https://stonecoldlove-homepage.firebaseio.com',
//       projectId: 'stonecoldlove-homepage',
//       storageBucket: 'stonecoldlove-homepage.appspot.com',
//       messagingSenderId: '537988039329',
//       appId: '1:537988039329:web:ca4f131723c719c388a6e0'
//     }
//     // Initialize Firebase
//     firebase.initializeApp(firebaseConfig)
//     const firestore = firebase.firestore()
//     const collection = firestore.collection('Hintergrundbild')
//     // var query = collection.where('fieldPath': 'status', 'value': 'published').limit('limit': 1)
//     const query = collection.where('status', '==', 'published').limit(1)

//     query.get().then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         const image = doc.data().image
//         // this.setState({ image })
//       })
//     })
//     this.state = {
//       image: logo
//     }
//   }
//   render() {
//     return (
//         <div>
//           <div className="logo">
//             <div className="logoTitel">STONE COLD LOVE</div>
//           </div>
//           <img src={this.state.image} className="App-logo" alt="logo" />
//         </div>
//     )
//   }
// }

import React from 'react'
import { Root, Routes } from 'react-static'
import { Location as ReachLocation, Router } from '@reach/router'
import './styles/pages/app.scss'
import { ParallaxProvider } from 'react-scroll-parallax'
import logoSw from './icons/logo_sw.svg'

function App() {
  return (
    <Root>
      <ParallaxProvider scrollAxis="vertical">
        <ReachLocation>
          {
            ({ location: reachlocation }) =>
              <div className="scl-style content">
                <React.Suspense fallback={
                  <div className="loadingSpinner">
                    <img width={100} src={logoSw} className="logo transpartent" />
                    <span className="loadinText">LADE</span>
                  </div>
                }>
                  <Router location={reachlocation}>
                    <Routes path="*" />
                  </Router>
                </React.Suspense>
              </div>
          }
        </ReachLocation>
      </ParallaxProvider>
    </Root>
  )
}

export default App
