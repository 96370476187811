import React from 'react'
import { Head, withRouteData, withSiteData } from 'react-static'
import Start from './../componets/Startanimation'
import './../styles/pages/youtube.scss'
import { withController } from 'react-scroll-parallax'
import PropTypes from 'prop-types'
import { AppConsumer } from '../context'
import CookiesBanner from './../componets/CookiesHeader/index'
import ContextProvider from './../componets/ContextProvider'
import { isElementInViewport } from './../utils/utils'
import metaHeader from './../componets/MetaHeader'

class YoutubeClass extends React.Component {
  constructor(props) {
    super()
    this.state = {
      youtubeCouldBeAnimated: true,
      showYoutube: false
    }
    this.handleLoad = this.handleLoad.bind(this)
    this.onScroll = this.onScroll.bind(this)
  }
  handleLoad() {
    this.props.parallaxController.update()
  }
  onScroll() {
    const {
      youtubeCouldBeAnimated
    } = this.state

    const youtubeIsVisible = isElementInViewport(document.getElementsByClassName('youtubeSection')[0])

    if (youtubeCouldBeAnimated) {
      if (youtubeIsVisible) {
        this.setState({ showYoutube: true, youtubeCouldBeAnimated: false })
      }
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      window.addEventListener('scroll', this.onScroll, false)
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onScroll, false)
    }
  }
  render() {
    const { videoId, controls, mute, rel, autoplay } = this.props.youtubeData
    const ytParams = `${videoId}?controls=${controls}&mute=${mute}&rel=${rel}&autoplay=${autoplay}&wmode=transparent`
    return <AppConsumer>{(context) =>
      <div className="youtubeSection pageWrapper" >
        {
          context.cookiesAccepted && this.state.showYoutube
            ? <div>
              <iframe className="youtubeVideo" width="100%" height="510" src={`https://www.youtube.com/embed/${ytParams}`}
                frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            </div>
            : <CookiesBanner alternative={true} />
        }
      </div>
    }</AppConsumer>
  }
}
YoutubeClass.propTypes = {
  parallaxController: PropTypes.object,
  youtubeData: PropTypes.object
}
export const Youtube = withRouteData(withSiteData(withController(YoutubeClass)))
const combinedYoutube = ({ youtubeData }) => {
  return <div className="youtubeSinglePage">
    <ContextProvider>
      <Head>
        {metaHeader(youtubeData)}
      </Head>
      <Start />
      <Youtube />
    </ContextProvider>
  </div>
}

export default withRouteData(withSiteData(combinedYoutube))
