import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FallbackImg from './images/fallbackImg.svg'
import get from 'lodash/get'

const getFallbackSize = (size) => {
  const sizes = {
    small: '32px',
    large: '64px'
  }
  return get(sizes, size, sizes.small)
}

class FallbackImage extends Component {
  render() {
    const {
      className,
      fallbackImgSize
    } = this.props

    const fallbackImgStyle = {
      backgroundImage: `url(${FallbackImg})`,
      backgroundSize: getFallbackSize(fallbackImgSize)
    }
    const divFallBackClasses = classNames(
      'webpictureFallbackImg',
      className
    )

    return <div className={divFallBackClasses} style={fallbackImgStyle} ></div>
  }
}

FallbackImage.propTypes = {
  className: PropTypes.string,
  fallbackImgSize: PropTypes.string
}

FallbackImage.defaultProps = {
  className: '',
  fallbackImgSize: 'small'
}

export default FallbackImage
