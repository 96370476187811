import React from 'react'
import { Head, withSiteData, withRouteData } from 'react-static'
import Start from './../componets/Startanimation'
import ContextProvider from './../componets/ContextProvider'
import BandMemberComponete from './../componets/BandMember'
import { withController } from 'react-scroll-parallax'
import PropTypes from 'prop-types'
import metaHeader from './../componets/MetaHeader'

class AlexClass extends React.Component {
  render() {
    return <BandMemberComponete
      parallaxController={this.props.parallaxController}
      bandmemberData={this.props.alexData}
    />
  }
}
AlexClass.propTypes = {
  parallaxController: PropTypes.object,
  alexData: PropTypes.object
}

export const Alex = withRouteData(withSiteData(withController(AlexClass)))

const combinedAlex = ({ alexData }) => {
  // TODO: use stuctured data for album
  /* const tracks = {
    '@context': 'http://schema.org',
    '@type': 'MusicAlbum',
    '@id': 'https://musicbrainz.org/release/xxxxxx',
    byArtist: {
      '@type': 'MusicGroup',
      name: 'Bandname',
      alternateName: 'Alternative Name',
      '@id': 'https://musicbrainz.org/artist/xxxxxx',
      url: 'https://yourwebsite.com'
    },
    genre: [
      'Progressive Metal',
      'Metal'
    ],
    image: 'https://yourwebsite.com/albumcoverimage.jpg',
    name: 'Album Name',
    numTracks: '6',
    track: [
      {
        '@type': 'MusicRecording',
        '@id': 'https://musicbrainz.org/recording/xxxxxx',
        position: '1',
        duration: 'PT4M28S',
        name: 'Track Name',
        url: 'https://yourwebsite.com/track/xxxxxx'
      },
      {
        '@type': 'MusicRecording',
        '@id': 'https://musicbrainz.org/recording/xxxxxx',
        position: '2',
        duration: 'PT5M06S',
        name: 'Track Name',
        url: 'https://yourwebsite.com/track/xxxxxx'
      },
      {
        '@type': 'MusicRecording',
        '@id': 'https://musicbrainz.org/recording/xxxxxx',
        position: '3',
        duration: 'PT3M21S',
        name: 'Track Name',
        url: 'https://yourwebsite.com/track/xxxxxx'
      },
      {
        '@type': 'MusicRecording',
        '@id': 'https://musicbrainz.org/recording/xxxxxx',
        position: '4',
        duration: 'PT3M56S',
        name: 'Track Name',
        url: 'https://yourwebsite.com/track/xxxxxx'
      },
      {
        '@type': 'MusicRecording',
        '@id': 'https://musicbrainz.org/recording/xxxxxx',
        position: '5',
        duration: 'PT5M03S',
        name: 'Track Name',
        url: 'https://yourwebsite.com/track/xxxxxx'
      },
      {
        '@type': 'MusicRecording',
        '@id': 'https://musicbrainz.org/recording/xxxxxx',
        position: '6',
        duration: 'PT3M24S',
        name: 'Track Name',
        url: 'https://yourwebsite.com/track/xxxxxx'
      }
    ],
    url: 'https://yourwebsite.com/album/xxxxxx'
  } */
  const sameAs = []
  if (alexData.facebook) {
    sameAs.push(alexData.facebook)
  }
  if (alexData.instagram) {
    sameAs.push(alexData.instagram)
  }
  const data = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: alexData.name,
    alternateName: alexData.alternateName,
    description: alexData.text,
    url: alexData.canonical,
    sameAs
  }
  const script = JSON.stringify(data)
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(alexData)}
        <script type="application/ld+json">{script}</script>
      </Head>
      <Start/>
      <Alex/>
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(combinedAlex)
