import React from 'react'

const Header = (metaData) => {
  const metaReturnObject = []
  const metaImages = []
  if (metaData.images) {
    metaData.images.data.map((img, i) => {
      const image = img.attributes
      metaImages.push(<meta key={i} property="og:image" content={image.url}/>)
      metaImages.push(<meta key={i} property="og:image:type" content={image.mime}/>)
      metaImages.push(<meta key={i} property="og:image:width" content={image.width}/>)
      metaImages.push(<meta key={i} property="og:image:height" content={image.height}/>)
      metaImages.push(<meta key={i} property="og:image:alt" content={image.alternativeText}></meta>)
      metaImages.push(<meta key={i} property="twitter:image:src" content={image.url}/>)
      metaImages.push(<meta key={i} property="twitter:image:alt" content={image.alternativeText}></meta>)
      return null
    })
  }
  metaReturnObject.push(<meta key={1} charSet="UTF-8" />)
  metaReturnObject.push(<meta key={2} httpEquiv="content-language" content="de" />)
  metaReturnObject.push(<meta key={3}name="robots" content="index, follow"/>)
  metaReturnObject.push(<meta key={4}name="twitter:card" content="summary"/>)
  metaReturnObject.push(<meta key={5}name="viewport" content="width=device-width, initial-scale=1.0" />)
  metaReturnObject.push(
    <meta key={6} name="google-site-verification" content="xiMDNPW47oMbcOWrmdzZK9oWjBj9z2jgznNF30XYPOg" />
  )
  metaReturnObject.push(<meta key={7}property="og:type" content="website" />)
  metaReturnObject.push(<meta key={8}property="og:description" content={metaData.metaDescription} />)
  metaReturnObject.push(<meta key={9}property="og:title" content={metaData.metaTitle} />)
  metaReturnObject.push(<meta key={10}property="og:url" content={metaData.canonical}></meta>)
  metaReturnObject.push(<link key={11}rel="canonical" href={metaData.canonical} />)
  metaReturnObject.push(<meta key={12}name="description" content={metaData.metaDescription} />)
  metaReturnObject.push(<meta key={13} property="twitter:description" content={metaData.metaDescription} />)
  metaReturnObject.push(<meta key={14} property="twitter:site" content={metaData.canonical}></meta>)
  metaReturnObject.push(<meta key={15}property="twitter:title" content={metaData.metaTitle}/>)
  metaReturnObject.push(metaImages)
  metaReturnObject.push(<meta key={16}name="keywords" content={metaData.keywords} />)
  metaReturnObject.push(<title key={17}>{metaData.metaTitle}</title>)
  return metaReturnObject
}

export default Header
