export function isElementInViewport(ele) {
  if (!ele) {
    return false
  }
  const rect = ele.getBoundingClientRect()
  return Math.round(rect.bottom) >= 0
    && Math.round(rect.right) >= 0
    && Math.round(rect.top) <= Math.round(window.innerHeight || document.documentElement.clientHeight)
    && Math.round(rect.left) <= Math.round(window.innerWidth || document.documentElement.clientWidth)
}
