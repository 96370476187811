import React from 'react'
import { Head, withSiteData, withRouteData } from 'react-static'
import Start from './../componets/Startanimation'
import ContextProvider from './../componets/ContextProvider'
import BandMemberComponete from './../componets/BandMember'
import { withController } from 'react-scroll-parallax'
import PropTypes from 'prop-types'
import metaHeader from './../componets/MetaHeader'

class ConnyClass extends React.Component {
  render() {
    return <BandMemberComponete
      parallaxController={this.props.parallaxController}
      bandmemberData={this.props.connyData}
    />
  }
}
ConnyClass.propTypes = {
  parallaxController: PropTypes.object,
  connyData: PropTypes.object
}

export const Conny = withRouteData(withSiteData(withController(ConnyClass)))

const combinedConny = ({ connyData }) => {
  const sameAs = []
  if (connyData.facebook) {
    sameAs.push(connyData.facebook)
  }
  if (connyData.instagram) {
    sameAs.push(connyData.instagram)
  }
  const data = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: connyData.name,
    alternateName: connyData.alternateName,
    description: connyData.text,
    url: connyData.canonical,
    sameAs
  }
  const script = JSON.stringify(data)
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(connyData)}
        <script type="application/ld+json">{script}</script>
      </Head>
      <Start/>
      <Conny/>
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(combinedConny)
