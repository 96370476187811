import React from 'react'
import { Head, withSiteData, withRouteData } from 'react-static'
import { Posts } from './posts'
import { Impressum } from './impressum'
import { Termine } from './termine'
import { Band } from './band'
import { Rod } from './rod'
import { Alex } from './alex'
import { Basti } from './basti'
import { Conny } from './conny'
import { Sebi } from './sebi'
import Start from './../componets/Startanimation'
import { Youtube } from './youtube'
import { Spotify } from './spotify'
import './../styles/pages/index.scss'
import metaHeader from './../componets/MetaHeader'
import ScrollableSection from './../componets/ScrollableSection'
import ScrollingColorBackground from 'react-scrolling-color-background'
import ContextProvider from './../componets/ContextProvider'
import Scroll from 'react-scroll'
import PropTypes from 'prop-types'

const ScrolltoElement = Scroll.Element
import classNames from 'classnames'

class Index extends React.Component {
  constructor() {
    super()
    this.state = {
      visiblity: false
    }
  }

  render() {
    const { schemaMusicGroup, sections, indexMetaData } = this.props
    const script = JSON.stringify(schemaMusicGroup)
    const containerClasses = classNames(
      'indexWrapper',
      { visiblity: this.state.visiblity }
    )
    const scrollPlugin = Scroll.scroller
    const goTolink = (anchor) => {
      if (typeof document !== 'undefined' && typeof window !== 'undefined') {
        scrollPlugin.scrollTo(anchor, {
          duration: 2000,
          delay: 100,
          offset: 10,
          smooth: true })
      }
    }
    const getSection = (section) => {
      let componente
      switch (section) {
        case 'Start':
          componente = <Start callback={(anchor) => {
            this.setState({ visiblity: true }, () => {
              goTolink(anchor)
              if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                window.setTimeout(() => {
                  this.setState({ visiblity: false })
                }, 1500)
              }
            })
          }} variant="main"/>
          break
        case 'Impressum':
          componente = <Impressum/>
          break
        case 'Spotify':
          componente = <Spotify/>
          break
        case 'Posts':
          componente = <Posts/>
          break
        case 'Sebi':
          componente = <Sebi/>
          break
        case 'Basti':
          componente = <Basti/>
          break
        case 'Conny':
          componente = <Conny/>
          break
        case 'alex':
          componente = <Alex/>
          break
        case 'Rod':
          componente = <Rod/>
          break
        case 'Band':
          componente = <Band/>
          break
        case 'Youtube':
          componente = <Youtube/>
          break
        case 'Termine':
          componente = <Termine onIndex={true}/>
          break
        default:
          componente = <></>
      }
      return componente
    }
    return <ContextProvider>
      <Head>
        {metaHeader(indexMetaData)}
        <script type="application/ld+json">{script}</script>
      </Head>
      <div className={containerClasses}>
        <ScrollingColorBackground
          selector=".js-color-stop[data-background-color]"
          colorDataAttribute="data-background-color"
          initialRgb="rgb(255, 255, 255)" />
        {
          sections.map((section, i) => {
            return <ScrollableSection key={i} offset={-300} name={section.url} meta={section.meta}>
              <ScrolltoElement
                key={i}
                name={section.url}
                className="js-color-stop"
                data-background-color={section.color}>
                {getSection(section.componete)}
              </ScrolltoElement>
            </ScrollableSection>
          })
        }
      </div>
    </ContextProvider>
  }
}
Index.propTypes = {
  sections: PropTypes.array,
  schemaMusicGroup: PropTypes.object,
  indexMetaData: PropTypes.object
}
export default withRouteData(withSiteData(Index))
