import React from 'react'
import './bandMember.scss'
import WebPicture from './../WebPicture'
import { isElementInViewport } from './../../utils/utils'
import classNames from 'classnames'
import { withController } from 'react-scroll-parallax'
import PropTypes from 'prop-types'
import FacebookLogo from './../../icons/facebook.svg'
import InstaLogo from './../../icons/instagram.svg'

class BandMemberClass extends React.Component {
  constructor() {
    super()
    this.state = {
      bandmemberCouldBeAnimated: true,
      turningPoint: 0,
      bandmemberIsVisible: false,
      showBandMemberPic: false
    }
    this.onScroll = this.onScroll.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
  }
  handleLoad() {
    this.props.parallaxController.update()
  }

  onScroll() {
    const {
      bandmemberCouldBeAnimated,
      turningPoint
    } = this.state
    const { bandmemberData } = this.props

    const currentScrollPosY = Math.round(window.scrollY)

    let {
      bandmemberIsVisible
    } = this.state

    bandmemberIsVisible = isElementInViewport(
      document.getElementsByClassName(`bandmemberHeadline${bandmemberData.name}`)[0])
    if (bandmemberCouldBeAnimated || currentScrollPosY <= turningPoint) {
      this.setState({ bandmemberCouldBeAnimated: true })
      if (bandmemberIsVisible) {
        this.setState({ showBandMemberPic: true, bandmemberCouldBeAnimated: false, turningPoint: currentScrollPosY })
      } else {
        this.setState({ showBandMemberPic: false })
      }
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      window.addEventListener('scroll', this.onScroll, false)
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onScroll, false)
    }
  }

  render() {
    const { bandmemberData } = this.props
    const bandmemberpicClasses = classNames(
      { bandmemberPicRight: bandmemberData.animation === 'right' },
      { bandmemberPicLeft: bandmemberData.animation === 'left' },
      { hidden: !this.state.showBandMemberPic }
    )
    const bandmembertextClasses = classNames(
      { bandmemberTextLeft: bandmemberData.animation === 'right' },
      { bandmemberTextRight: bandmemberData.animation === 'left' },
      { hidden: !this.state.showBandMemberPic }
    )
    const bandmemberHeadlineClasses = classNames(
      `bandmemberHeadline${bandmemberData.name}`,
      'text-center',
      'bandmemberHeadline'
    )
    const bandMemberText = <div className={bandmembertextClasses}>
      <p>{bandmemberData.text}</p>
      {bandmemberData.facebook &&
          <a
            href={bandmemberData.facebook}
            rel="noopener noreferrer"
            target="_blank"
            title={`${bandmemberData.name} on Facebook`}>
            <img src={FacebookLogo} className="icon animated" alt="stilisiertes schwarz weißes Facebook Logo" />
          </a>
      }
      {bandmemberData.instagram &&
          <a href={bandmemberData.instagram}
            rel="noopener noreferrer"
            target="_blank"
            title={`${bandmemberData.name} on Instagram`}>
            <img src={InstaLogo} className="icon animated" alt="stilisiertes schwarz weißes Instagram Logo" />
          </a>
      }
    </div>
    // TODO: bring back retina and co
    const bandMemberPic = <div>
      <WebPicture
        className={bandmemberpicClasses}
        alt={`${bandmemberData.name}`}
        src={bandmemberData.images.data[0].attributes.url}
        onLoad={() => {
          this.handleLoad()
        }}
      />
    </div>
    return <div className="pageWrapper" >
      <h1 className={bandmemberHeadlineClasses}>{bandmemberData.title}</h1>
      <div className="bandmemberContend">
        {bandmemberData.picturePosition === 'bottom' && bandMemberText}
        {bandMemberPic}
        {bandmemberData.picturePosition === 'top' && bandMemberText}
      </div>
    </div>
  }
}

BandMemberClass.propTypes = {
  parallaxController: PropTypes.object,
  bandmemberData: PropTypes.object
}

export default withController(BandMemberClass)

