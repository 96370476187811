import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import BackgroundImage from './../BackgroundImage'
import FallbackImage from './../FallbackImage'
import './index.scss'

export const isValidImage = (src) => {
  const image = new Image()
  return new Promise(
    (resolve) => {
      image.onerror = function() {
        resolve(false)
      }
      image.onload = function() {
        resolve(true)
      }
      image.src = src
    })
}

class WebPicture extends Component {
  constructor(props) {
    super(props)
    const { src, fallback } = props
    this.state = {
      isValidImageSource: true
    }
    if (fallback) {
      this.validateImageSource(src)
    }
  }

  validateImageSource(imageSrc) {
    isValidImage(imageSrc).then((isValid) => {
      if (this.state.isValidImageSource === !isValid) {
        this.setState({
          isValidImageSource: isValid
        })
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { src, fallback } = this.props
    if (fallback && src !== prevProps.src) {
      this.validateImageSource(src)
    }
  }

  render() {
    const {
      alt,
      srcSets,
      width,
      height,
      src,
      size,
      className,
      position,
      fallback,
      fallbackImgSize,
      onLoad,
      asBackground,
      ...other
    } = this.props
    const { isValidImageSource } = this.state

    if (!isValidImageSource && fallback) {
      return <FallbackImage className={className} fallbackImgSize={fallbackImgSize} />
    }

    if (asBackground) {
      return <BackgroundImage alt={alt} size={size} position={position} src={src} className={className} {...other} />
    }

    const pictureClasses = classNames(
      'picture',
      className
    )

    let Sources = ''
    if (srcSets) {
      Sources = Object.keys(srcSets).map((srcSet) => {
        const currentSrcSet = srcSets[srcSet]
        let mediaPrefix = ''
        let mediaQuery = ''
        const [currentSrcSetimgUrl] = src.split(/\.(?=[^.]+$)/)
        const srcType = currentSrcSet.imgType === 'svg' ? 'svg+xml' : currentSrcSet.imgType
        if (currentSrcSet.mediaQuery) {
          mediaPrefix = `_-_${currentSrcSet.mediaQuery}w`
          mediaQuery = `(max-width: ${currentSrcSet.mediaQuery}px)`
        }
        let ImgUrls = ''

        ImgUrls = `${currentSrcSetimgUrl}${mediaPrefix}.${currentSrcSet.imgType}`
        if (currentSrcSet.retina) {
          ImgUrls = `${ImgUrls} 1x,\
 ${currentSrcSetimgUrl}${mediaPrefix}@2x.${currentSrcSet.imgType} 2x`
        }

        return (
          <source
            key={`${currentSrcSet.imgType}-${mediaQuery}`}
            type={`image/${srcType}`}
            media={mediaQuery}
            srcSet={ImgUrls}
          />
        )
      })
    }
    return (
      <picture {...other}>
        {Sources}
        <img onLoad={onLoad} className={pictureClasses} src={src} alt={alt} width={width} height={height} />
      </picture>
    )
  }
}

WebPicture.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  position: PropTypes.string,
  size: PropTypes.string,
  fallback: PropTypes.bool,
  onLoad: PropTypes.func,
  fallbackImgSize: PropTypes.oneOf(['small', 'large']),
  asBackground: PropTypes.bool,
  srcSets: PropTypes.arrayOf(PropTypes.shape({
    imgType: PropTypes.string.isRequired,
    retina: PropTypes.bool,
    mediaQuery: PropTypes.string
  }))
}

WebPicture.defaultProps = {
  srcSets: undefined,
  className: '',
  size: 'contain',
  width: undefined,
  height: undefined,
  asBackground: false,
  position: 'center',
  fallback: false,
  fallbackImgSize: 'small'
}

export default WebPicture
