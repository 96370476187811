import React from 'react'
import { Head, withSiteData, withRouteData } from 'react-static'
import Start from './../componets/Startanimation'
import './../styles/pages/termine.scss'
import ContextProvider from './../componets/ContextProvider'
import { isElementInViewport } from './../utils/utils'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import metaHeader from './../componets/MetaHeader'

class TermineClass extends React.Component {
  constructor(props) {
    super()
    this.state = {
      timelineIsVisible: false,
      timelineCouldBeAnimated: true,
      showDates: false,
      turningPoint: 0
    }
    this.onScroll = this.onScroll.bind(this)
  }

  onScroll() {
    const {
      timelineCouldBeAnimated, turningPoint
    } = this.state
    const currentScrollPosY = Math.round(window.scrollY)

    let {
      timelineIsVisible
    } = this.state

    timelineIsVisible = isElementInViewport(document.getElementsByClassName('timeline')[0])

    if (timelineCouldBeAnimated || currentScrollPosY <= turningPoint) {
      this.setState({ timelineCouldBeAnimated: true })
      if (timelineIsVisible) {
        this.setState({ showDates: true, timelineCouldBeAnimated: false, turningPoint: currentScrollPosY })
      } else {
        this.setState({ showDates: false })
      }
    }
  }

  componentDidMount() {
    const { onIndex, events } = this.props
    const sortByDate = (objA, objB) => {
      if (moment(objA.date) < moment(objB.date)) {
        return !onIndex ? 1 : -1
      }
      if (moment(objA.date) > moment(objB.date)) {
        return !onIndex ? -1 : 1
      }
      return 0
    }
    const sortedEvents = events.reduce((groups, item) => {
      const date = moment(item.date)
      const now = moment()
      const year = date.year()
      const group = groups[year] || []
      if (date.diff(now) <= 0) {
        item.isInPast = true
      }
      group.push(item)
      groups[year] = group.sort(sortByDate)
      return groups
    }, {})
    this.setState({ sortedEvents })
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      window.addEventListener('scroll', this.onScroll, false)
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onScroll, false)
    }
  }
  render() {
    const { showDates, sortedEvents } = this.state
    const { onIndex, events, terminMetaData } = this.props
    let eventsTable
    if (sortedEvents && !onIndex) {
      eventsTable = Object.keys(sortedEvents).toReversed()
        .map((sortedEventYear, i) => {
          const eventsToRender = []
          sortedEvents[sortedEventYear].map((eventItem, x) => {
            if (!eventItem.isInPast || !onIndex) {
              const timelineBoxClasses = classNames(
                'timeline__box',
                { outdated: eventItem.isInPast },
                { hidden: !showDates }
              )
              const timelineDateClasses = classNames(
                'timeline__date',
                { outdated: eventItem.isInPast }
              )
              const timelineContentClasses = classNames(
                'timeline__content',
                { outdated: eventItem.isInPast }
              )
              eventsToRender.push(
                <div key={`event${x}of${i}`} className={timelineBoxClasses}>
                  <div className={timelineDateClasses}>
                    <span className="timeline__day">{moment(eventItem.date).format('DD')}</span>
                    <span className="timeline__month">{moment(eventItem.date).format('MMM')}</span>
                  </div>
                  <div className="timeline__post">
                    <a title={eventItem.linkTitle}
                      href={eventItem.linkUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={timelineContentClasses}>
                      <p><span className="lead">{eventItem.title}</span>
                        <br/><span className="info">{eventItem.info}</span></p>
                    </a>
                  </div>
                </div>
              )
            }
            return null
          })
          const timelineYearClasses = classNames(
            'timeline__year',
            { hidden: !showDates },
            { outdated: sortedEventYear - moment().year() < 0 }
          )
          return eventsToRender.length > 0 ? <div className="timeline__group" key={i}>
            <span className={timelineYearClasses}>{sortedEventYear}</span>
            {eventsToRender}
          </div> : null
        })
    } else {
      eventsTable = sortedEvents ? Object.keys(sortedEvents)
        .map((sortedEventYear, i) => {
          const eventsToRender = []
          sortedEvents[sortedEventYear].map((eventItem, x) => {
            if (!eventItem.isInPast || !onIndex) {
              const timelineBoxClasses = classNames(
                'timeline__box',
                { outdated: eventItem.isInPast },
                { hidden: !showDates }
              )
              const timelineDateClasses = classNames(
                'timeline__date',
                { outdated: eventItem.isInPast }
              )
              const timelineContentClasses = classNames(
                'timeline__content',
                { outdated: eventItem.isInPast }
              )
              eventsToRender.push(
                <div key={`event${x}of${i}`} className={timelineBoxClasses}>
                  <div className={timelineDateClasses}>
                    <span className="timeline__day">{moment(eventItem.date).format('DD')}</span>
                    <span className="timeline__month">{moment(eventItem.date).format('MMM')}</span>
                  </div>
                  <div className="timeline__post">
                    <a title={eventItem.linkTitle}
                      href={eventItem.linkUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={timelineContentClasses}>
                      <p><span className="lead">{eventItem.title}</span>
                        <br/><span className="info">{eventItem.info}</span></p>
                    </a>
                  </div>
                </div>
              )
            }
            return null
          })
          const timelineYearClasses = classNames(
            'timeline__year',
            { hidden: !showDates },
            { outdated: sortedEventYear - moment().year() < 0 }
          )
          return eventsToRender.length > 0 ? <div className="timeline__group" key={i}>
            <span className={timelineYearClasses}>{sortedEventYear}</span>
            {eventsToRender}
          </div> : null
        }) : null
    }
    return <div className="terminpage pageWrapper" >
      <Head>
        {
          events.map((eventItem, i) => {
            return <script key={`terminScript${i}`} type="application/ld+json">{JSON.stringify(eventItem.meta)}</script>
          })
        }
      </Head>
      <h1 className="text-center">{terminMetaData.headline}</h1>
      { eventsTable && eventsTable[eventsTable.length - 1] !== null ? <div className="timeline">
        {eventsTable}
      </div> : null}
      {onIndex && <a className="allDatesLink" href="/termine">{terminMetaData.showAllBtn}</a>}
    </div>
  }
}

TermineClass.propTypes = {
  onIndex: PropTypes.bool,
  events: PropTypes.array,
  terminMetaData: PropTypes.object
}
export const Termine = withRouteData(withSiteData(TermineClass))

const combinedTermine = ({ terminMetaData }) => {
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(terminMetaData)}
      </Head>
      <Start/>
      <Termine/>
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(withSiteData(combinedTermine))
