


// Template Map
export default {
  '__react_static_root__/src/pages/404.js': require('__react_static_root__/src/pages/404.js').default,
'__react_static_root__/src/pages/alex.js': require('__react_static_root__/src/pages/alex.js').default,
'__react_static_root__/src/pages/band.js': require('__react_static_root__/src/pages/band.js').default,
'__react_static_root__/src/pages/basti.js': require('__react_static_root__/src/pages/basti.js').default,
'__react_static_root__/src/pages/conny.js': require('__react_static_root__/src/pages/conny.js').default,
'__react_static_root__/src/pages/datenschutz.js': require('__react_static_root__/src/pages/datenschutz.js').default,
'__react_static_root__/src/pages/impressum.js': require('__react_static_root__/src/pages/impressum.js').default,
'__react_static_root__/src/pages/index.js': require('__react_static_root__/src/pages/index.js').default,
'__react_static_root__/src/pages/insta-check.js': require('__react_static_root__/src/pages/insta-check.js').default,
'__react_static_root__/src/pages/intern.js': require('__react_static_root__/src/pages/intern.js').default,
'__react_static_root__/src/pages/links.js': require('__react_static_root__/src/pages/links.js').default,
'__react_static_root__/src/pages/posts.js': require('__react_static_root__/src/pages/posts.js').default,
'__react_static_root__/src/pages/press.js': require('__react_static_root__/src/pages/press.js').default,
'__react_static_root__/src/pages/rod.js': require('__react_static_root__/src/pages/rod.js').default,
'__react_static_root__/src/pages/sebi.js': require('__react_static_root__/src/pages/sebi.js').default,
'__react_static_root__/src/pages/spotify.js': require('__react_static_root__/src/pages/spotify.js').default,
'__react_static_root__/src/pages/termine.js': require('__react_static_root__/src/pages/termine.js').default,
'__react_static_root__/src/pages/youtube.js': require('__react_static_root__/src/pages/youtube.js').default
}

export const notFoundTemplate = '__react_static_root__/src/pages/404.js'

