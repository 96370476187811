import React from 'react'
import logofull from './../../icons/logo.svg'
import logoSw from './../../icons/logo_sw.svg'
import arrow from './../../icons/arrow.svg'
import InstaLogo from './../../icons/instagram.svg'
import FBLogo from './../../icons/facebook.svg'
import SpotifyLogo from './../../icons/spotify.svg'
import YoutubeLogo from './../../icons/youtube.svg'
import CookiesBanner from './../CookiesHeader/index'
import './../../styles/pages/start.scss'
import classNames from 'classnames'
import { AppConsumer } from '../../context'
import PropTypes from 'prop-types'
import * as Scroll from 'react-scroll'

export default class StartPage extends React.Component {
  constructor(props) {
    super()
    this.sections = [
      'termine',
      'youtube',
      'band',
      'rod',
      'alex',
      'conny',
      'basti',
      'sebi',
      'posts',
      'spotify',
      'impressum'
    ]
    this.state = {
      currentSection: 'start',
      hideArrow: true,
      hideLogofull: true,
      hideLogoSw: true,
      fadeInLogofull: true,
      hideSocialLinks: true,
      hideCookiesBanner: true,
      titleStartPos: 0,
      titleCouldBeFixed: true,
      allowSwitchOffset1: true,
      allowSwitchOffset2: true,
      allowSwitchOffset3: true,
      turningPoint: 0,
      removeTitelOpacity: false,
      titelIsVisible: true,
      socialIconBlinINterval: false,
      animatedIcon: 0,
      offset2: 50
    }
    this.onScroll = this.onScroll.bind(this)
    this.socialIconBlink = this.socialIconBlink.bind(this)
  }
  socialIconBlink() {
    if (this.state && this.state.fixTitel) {
      const min = 1
      const max = 4
      const x = Math.floor(Math.random() * (max - min + 1)) + min
      this.setState({ animatedIcon: x })
    }
  }
  onScroll() {
    const {
      titleCouldBeFixed,
      turningPoint,
      allowSwitchOffset1,
      allowSwitchOffset2,
      allowSwitchOffset3
    } = this.state

    const currentScrollPosY = Math.round(window.scrollY)
    const offset1 = 50
    const offset2 = this.state.offset2
    const offset3 = 70
    const title = document.getElementsByClassName('logoTitel')[0]
    const titelPostion = title.getBoundingClientRect().y

    let {
      titelIsVisible
    } = this.state

    titelIsVisible = titelPostion >= 0

    if (titleCouldBeFixed || currentScrollPosY <= turningPoint) {
      this.setState({ titleCouldBeFixed: true })
      if (!titelIsVisible) {
        this.setState({ titleCouldBeFixed: false, turningPoint: currentScrollPosY })
      }
      this.setState({ fixTitel: !titelIsVisible, titleStartPos: turningPoint })
    }
    if (currentScrollPosY <= offset1) {
      this.setState({ allowSwitchOffset1: true, fadeInLogofull: true, hideArrow: false })
    }
    if (titelPostion >= offset2) {
      this.setState({ allowSwitchOffset2: true, hideCookiesBanner: false })
    }
    if (currentScrollPosY <= offset3) {
      this.setState({ allowSwitchOffset3: true, hideSocialLinks: true })
    }
    if (allowSwitchOffset1 && currentScrollPosY >= offset1) {
      clearTimeout(this.timer1)
      clearTimeout(this.timer2)
      clearTimeout(this.timer3)
      clearTimeout(this.timer4)
      clearTimeout(this.timer5)
      this.setState({ allowSwitchOffset1: false, hideLogoSw: false, fadeInLogofull: false, hideArrow: true })
    }
    if (allowSwitchOffset2 && titelPostion <= offset2) {
      this.setState({ allowSwitchOffset2: false, hideCookiesBanner: true })
    }
    if (allowSwitchOffset3 && currentScrollPosY >= offset3) {
      this.setState({ allowSwitchOffset3: false, hideSocialLinks: false })
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const configureAnchors = require('react-update-url-on-scroll').configureAnchors
      configureAnchors({
        onSectionEnter: (newState, oldState) => {
          if (newState.name) {
            const pos = this.sections.indexOf(newState.name)
            this.setState({ currentSection: pos })
          }
        }
      })
      this.timer1 = window.setTimeout(() => {
        this.setState({ removeTitelOpacity: true })
      }, 600)
      this.timer2 = window.setTimeout(() => {
        this.setState({ hideLogofull: false })
      }, 1500)
      this.timer3 = window.setTimeout(() => {
        this.setState({ hideCookiesBanner: false }, () => {
          const elm = document.getElementsByClassName('CookiesBannerWrapper')[0]
          const cookieBannerHeight = elm ? elm.offsetHeight : 0
          const offset2 = cookieBannerHeight !== 0 && cookieBannerHeight > 100 ? cookieBannerHeight : 100
          this.setState({ offset2 })
        })
      }, 3000)
      this.timer4 = window.setTimeout(() => {
        this.setState({ hideArrow: false })
      }, 4000)
      this.timer5 = window.setTimeout(() => {
        if (this.props.variant !== 'main') {
          Scroll.animateScroll.scrollTo(window.innerHeight - (window.innerHeight / 10))
        }
      }, 5000)
      window.onscroll = () => {
        window.addEventListener('scroll', this.onScroll, false)
      }
      this.setState({ socialIconBlinINterval: window.setInterval(this.socialIconBlink, 3000) })
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onScroll, false)
      window.clearInterval(this.state.socialIconBlinINterval)
    }
  }

  render() {
    const {
      fadeInLogofull,
      hideLogofull,
      hideLogoSw,
      fixTitel,
      hideArrow,
      hideSocialLinks,
      hideCookiesBanner,
      animatedIcon,
      currentSection,
      removeTitelOpacity
    } = this.state

    const logoClasses = classNames(
      'logo',
      { hidden: hideLogofull },
      { 'fade-in': fadeInLogofull },
      { 'fade-out': !fadeInLogofull }
    )
    const logoSwClasses = classNames(
      'logo transpartent',
      { hidden: hideLogoSw }
    )
    const titleClasses = classNames(
      'logoTitel',
      { opacity: !removeTitelOpacity },
      { fixed: fixTitel }
    )
    const backToMainPageClasses = classNames(
      'backToMainPage',
      { hidden: !fixTitel || this.props.variant === 'main' }
    )
    const arrowsClasses = classNames(
      'bouncingArrow',
      { hidden: hideArrow }
    )
    const socialIconsClasses = classNames(
      'socialIcons',
      { hidden: hideSocialLinks },
      { fixed: fixTitel }
    )
    const progressbarContainerClasses = classNames(
      'progressbarContainer',
      { hidden: !fixTitel || this.props.variant !== 'main' }
    )
    const instaIcon = classNames(
      'icon',
      { animated: animatedIcon === 1 }
    )
    const FacebookIcon = classNames(
      'icon',
      { animated: animatedIcon === 2 }
    )
    const SpotifyIcon = classNames(
      'icon',
      { animated: animatedIcon === 3 }
    )
    const YoutubeIcon = classNames(
      'icon',
      { animated: animatedIcon === 4 }
    )
    const scrollPlugin2 = Scroll.animateScroll

    const linker = (e, anchor) => {
      const posInArray = this.sections.indexOf(anchor)
      if (this.props.variant === 'main') {
        if (posInArray >= 0) {
          this.props.callback(anchor)
        } else {
          scrollPlugin2.scrollTo(0)
        }
        e.preventDefault()
      }
    }
    // TODO: add tool tips
    return <AppConsumer>
      { (context) =>
        <div className="startContainer">
          {
            !context.cookiesAccepted ?
              <CookiesBanner className={hideCookiesBanner ? 'hidden' : null} />
              : null
          }
          {
            typeof document === 'undefined' ?
              <div className="loadingSpinner">
                <img width={100} src={logoSw} className="logo transpartent" />
                <span className="loadinText">LADE</span>
              </div>
              : null
          }
          <div className="logoContainer">
            <div className="titleContainer">
              <a onClick={(e) => {
                linker(e, 'index')
              }} title="zur Startseite" href="/">
                <div className={titleClasses}>STONE COLD LOVE</div>
              </a>
            </div>
            <div className={backToMainPageClasses}>
              <a title="zur Startseite" href="/">
                zurück zur Startseite
              </a>
            </div>
            <div className="imageContainer">
              <img src={logoSw} className={logoSwClasses}
                alt="Stone Cold Love Band Logo - stilisiere Lippen/Mund mit Zähnen - Schwarz Weiss" />
              <img src={logofull} className={logoClasses}
                alt="Stone Cold Love Band Logo - rote Lippen/Mund mit Zähnen - Bunt" />
              <div className={socialIconsClasses}>
                <div className={progressbarContainerClasses}>
                  <ul className="progressbar">
                    {
                      this.sections.map((section, i) => {
                        return <li key={i} className={currentSection >= i ? 'active' : null}>
                          <a onClick={(e) => {
                            linker(e, section)
                          }} href={`/${section}`}>
                            <span></span>
                          </a>
                        </li>
                      })
                    }
                  </ul>
                </div>
                <a href="https://www.instagram.com/stone_cold_love_band/" rel="noopener noreferrer" target="_blank" title="Stone Cold Love on Instagram">
                  <img src={InstaLogo} className={instaIcon} alt="stilisiertes schwarz weißes Instagram Logo" />
                </a>
                <a href="https://www.facebook.com/StoneColdLoveMD/" rel="noopener noreferrer" target="_blank" title="Stone Cold Love on Facebook">
                  <img src={FBLogo} className={FacebookIcon} alt="stilisiertes schwarz weißes Facebook Logo" />
                </a>
                <a href="https://open.spotify.com/artist/5CSJkM7ZxKwJGffHIahMp8" rel="noopener noreferrer" target="_blank" title="Stone Cold Love on Spotify">
                  <img src={SpotifyLogo} className={SpotifyIcon} alt="stilisiertes schwarz weißes Spotify Logo" />
                </a>
                <a href="https://www.youtube.com/channel/UCMTDq5LCwKaqCfydxpmPH-Q" rel="noopener noreferrer" target="_blank" title="Stone Cold Love on Youtube">
                  <img src={YoutubeLogo} className={YoutubeIcon} alt="stilisiertes schwarz weißes Youtube Logo" />
                </a>
              </div>
            </div>
          </div>
          {
            this.props.variant === 'main'
              ? <a className={arrowsClasses} onClick={(e) => {
                linker(e, 'termine')
              }} href="/termine">
                <span className="arrowtext">scroll down</span>
                <br/>
                <img src={arrow} alt="hüpfender Pfeil nach unten" />
              </a>
              : <a className={arrowsClasses} onClick={(e) => {
                scrollPlugin2.scrollTo(window.innerHeight)
                e.preventDefault()
              }} href="/">
                <span className="arrowtext">scroll down</span>
                <br/>
                <img src={arrow} alt="hüpfender Pfeil nach unten" />
              </a>
          }
        </div>
      }
    </AppConsumer>
  }
}
StartPage.propTypes = {
  variant: PropTypes.string,
  callback: PropTypes.func
}
