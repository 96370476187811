import React from 'react'
import { Head, withRouteData, withSiteData } from 'react-static'
import Start from './../componets/Startanimation'
import './../styles/pages/spotify.scss'
import { withController } from 'react-scroll-parallax'
import PropTypes from 'prop-types'
import { AppConsumer } from '../context'
import CookiesBanner from './../componets/CookiesHeader/index'
import ContextProvider from './../componets/ContextProvider'
import { isElementInViewport } from './../utils/utils'
import metaHeader from './../componets/MetaHeader'

class SpotifyClass extends React.Component {
  constructor(props) {
    super()
    this.state = {
      spotifyCouldBeAnimated: true,
      showSpotify: false
    }
    this.handleLoad = this.handleLoad.bind(this)
    this.onScroll = this.onScroll.bind(this)
  }
  handleLoad() {
    this.props.parallaxController.update()
  }
  onScroll() {
    const {
      spotifyCouldBeAnimated
    } = this.state

    const spotifyIsVisible = isElementInViewport(document.getElementsByClassName('spotifySection')[0])

    if (spotifyCouldBeAnimated) {
      if (spotifyIsVisible) {
        this.setState({ showSpotify: true, spotifyCouldBeAnimated: false })
      }
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      window.addEventListener('scroll', this.onScroll, false)
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onScroll, false)
    }
  }
  render() {
    return <AppConsumer>{(context) =>
      <div className="spotifySection pageWrapper" >
        <h1 className="text-center">Spotify</h1>
        {
          // TODO: add metatags if false
          // TODO: get from JSON or API
          context.cookiesAccepted && this.state.showSpotify
            ? <>
              <iframe src="https://open.spotify.com/embed/artist/5CSJkM7ZxKwJGffHIahMp8?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </>
            : <CookiesBanner alternative={true} />
        }
      </div>
    }</AppConsumer>
  }
}
SpotifyClass.propTypes = {
  parallaxController: PropTypes.object
}

export const Spotify = withRouteData(withSiteData(withController(SpotifyClass)))
const combinedSpotify = ({ spotifyData }) => {
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(spotifyData)}
      </Head>
      <Start />
      <Spotify />
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(combinedSpotify)
