/* eslint-disable camelcase */
import React from 'react'
import { Head, withRouteData, withSiteData } from 'react-static'
import Start from './../componets/Startanimation'
import './../styles/pages/press.scss'
import PropTypes from 'prop-types'
import ContextProvider from './../componets/ContextProvider'
import metaHeader from './../componets/MetaHeader'
import fileIcon from './../icons/file.svg'
import imgIcon from './../icons/imgIcon.png'
import downloadIcon from './../icons/download.svg'
import ReactMarkdown from 'react-markdown'
const getSizes = (sizes) => {
  if (sizes < 1000) {
    return `${sizes.toFixed(0)}  KB`
  }
  return `${(sizes / 1000).toFixed(2)} MB`
}
const getIconImag = (press) => {
  if (press.mime === 'image/jpeg' || press.mime === 'image/png' || press.mime === 'image/gif') {
    return <img src={press.url} alt={press.alternativeText} className="pressBoard__picture"/>
  } else if (press.mime === 'image/tiff') {
    return <img src={imgIcon} alt="imageIcon" className="pressBoard__picture"/>
  }
  return <img src={fileIcon} alt="fileIcon" className="pressBoard__picture"/>
}
const PressClass = ({ pressData }) => {
  return <div className="pressSection pageWrapper" >
    <h1 className="text-center">{pressData.title}</h1>
    <div className="pressBoard">
      <div><ReactMarkdown>{pressData.text}</ReactMarkdown></div>
      <div className="pressBoard__profiles">
        {
          pressData.images.data.map((data, i) => {
            const press = data.attributes
            return <a href={press.url} key={`press${i}`} download className="pressBoard__profile">
              {getIconImag(press)}
              <span className="pressBoard__name">{press.caption}</span>
              <span className="pressBoard__value">
                <span>{press.mime}</span>
                <br/>
                <span>{getSizes(press.size)}</span>
              </span>
              <img height="30px" src={downloadIcon} alt="downloadIcon" className="pressBoard__download"/>
            </a>
          })
        }
      </div>
    </div>
  </div>
}
PressClass.propTypes = {
  pressData: PropTypes.object
}

export const Press = withRouteData(withSiteData(PressClass))
const combinedPress = ({ pressData }) => {
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(pressData)}
      </Head>
      <Start />
      <Press />
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(combinedPress)
