import React from 'react'
import PropTypes from 'prop-types'

let ScrollableSection = (props) => <div>{props.children}</div>

if (typeof document !== 'undefined' && typeof window !== 'undefined') {
  ScrollableSection = require('react-update-url-on-scroll').default
}

ScrollableSection.propTypes = {
  children: PropTypes.node
}

export default ScrollableSection
