import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class BackgroundImage extends Component {
  render() {
    const {
      src,
      className,
      position,
      size,
      alt
    } = this.props

    const divBGClasses = classNames(
      'webpictureBackgroundImg',
      className
    )
    const backgroundImgStyle = {
      backgroundImage: `url(${src})`,
      backgroundSize: size,
      backgroundPosition: position
    }

    return <div aria-label={alt} role="img" className={divBGClasses} style={backgroundImgStyle} ></div>
  }
}

BackgroundImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  position: PropTypes.string
}

BackgroundImage.defaultProps = {
  className: '',
  size: 'contain',
  position: 'center'
}

export default BackgroundImage
