/* eslint-disable camelcase */
import React from 'react'
import { Head, withRouteData, withSiteData } from 'react-static'
import Start from './../componets/Startanimation'
import './../styles/pages/posts.scss'
import PropTypes from 'prop-types'
import { AppConsumer } from '../context'
import CookiesBanner from './../componets/CookiesHeader/index'
import ContextProvider from './../componets/ContextProvider'
import classNames from 'classnames'
import metaHeader from './../componets/MetaHeader'

const PostsClass = ({ posts, postData }) => {
  return <AppConsumer>{(context) => {
    const postsSectionClasses = classNames(
      'postsSection',
      'pageWrapper',
      { small: !context.cookiesAccepted }
    )
    return <div className={postsSectionClasses} >
      <h1 className="text-center">{postData.title}</h1>
      {
        context.cookiesAccepted
          ? <ul>
            {
              posts.map((post, i) => {
                let output
                const { id, caption, media_type, media_url } = post
                switch (media_type) {
                  case 'VIDEO':
                    output =
                          <video
                            width="90%"
                            height="auto"
                            src={media_url}
                            type="video/mp4"
                            controls playsinline>
                          </video>
                      
                    break
                  case 'CAROUSEL_ALBUM':
                    output =
                          <img
                            width="272"
                            height="272"
                            id={id}
                            src={media_url}
                            alt={caption}
                          />
                      
                    break
                  default:
                    output =
                          <img
                            width="272"
                            height="272"
                            id={id}
                            src={media_url}
                            alt={caption}
                          />
                }
          
                return <li key={i}>
                  {output}
                  <span>{post.caption}</span>
                </li>
              })
            }
          </ul>
          : <CookiesBanner alternative={true} />
      }
      <React.Fragment>
        {
          posts.map((post, i) => {
            return <div key={i} role="article" aria-label={post.caption}></div>
          })
        }
      </React.Fragment>
    </div>
  }
  }</AppConsumer>
}
PostsClass.propTypes = {
  posts: PropTypes.array,
  postData: PropTypes.object
}

export const Posts = withRouteData(withSiteData(PostsClass))
const combinedPosts = ({ postData }) => {
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(postData)}
      </Head>
      <Start />
      <Posts />
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(combinedPosts)
