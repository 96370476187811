import React from 'react'
import { Head, withSiteData } from 'react-static'
import Start from './../componets/Startanimation'
import ContextProvider from './../componets/ContextProvider'

export const Datenschutz = () => {
  return <React.Fragment>
    <p>
      Wir, Stone Cold Love, haben diese Datenschutzerklärung (Fassung 06.01.2020) verfasst,
      um Dir gemäß der Vorgaben der&nbsp;
      <a
        href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311140809"
        rel="noopener noreferrer"
        target="_blank">
        Datenschutz-Grundverordnung (EU) 2016/679
      </a>&nbsp;
          zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten
          Du als Besucher dieser Webseite hast.
    </p>
    <p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der
          Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</p>
    <h2>Automatische Datenspeicherung</h2>
    <p>
      Wenn Du heutzutage Webseiten besuchst, werden gewisse Informationen automatisch erstellt und gespeichert,
      so auch auf dieser Webseite.
    </p>
    <p>
      Wenn Du unsere Webseite so wie jetzt gerade besuchst, speichert unser Webserver (Computer auf dem diese
          Webseite gespeichert ist) automatisch Daten wie</p>
    <ul>
      <li>die Adresse (URL) der aufgerufenen Webseite</li>
      <li>Browser und Browserversion</li>
      <li>das verwendete Betriebssystem</li>
      <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
      <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
      <li>Datum und Uhrzeit</li>
    </ul>
    <p>in Dateien (Webserver-Logfiles).</p>
    <p>
      In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht.
      Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim
           Vorliegen von rechtswidrigem Verhalten eingesehen werden.<br />
      Die Rechtsgrundlage besteht nach&nbsp;
      <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311140809"
        target="_blank"
        rel="noopener noreferrer">
        Artikel 6 Absatz 1 f DSGVO
      </a>&nbsp;
          (Rechtmäßigkeit der Verarbeitung) darin, dass berechtigtes Interesse daran besteht,
          den fehlerfreien Betrieb dieser Webseite durch das Erfassen von Webserver-Logfiles zu ermöglichen.
    </p>
    <h2>Hosting</h2>
    <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:<br/>
      <b>All-Inkl</b><br/>
Anbieter ist die ALL-INKL.COM - Neue Medien Münnich, Inh. René Münnich, Hauptstraße 68, 02742
Friedersdorf (nachfolgend All-Inkl). Details entnehmen Sie der Datenschutzerklärung von All-Inkl:
      <a
        href="https://all-inkl.com/datenschutzinformationen/"
        target="_blank"
        rel="noopener noreferrer">
        https://all-inkl.com/datenschutzinformationen/.
      </a>
Die Verwendung von All-Inkl erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein
berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine
entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art.
6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den
Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG
umfasst. Die Einwilligung ist jederzeit widerrufbar.
Auftragsverarbeitung<br/>
Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren
Weisungen und unter Einhaltung der DSGVO verarbeitet.</p>
    <h2>Cookies</h2>
    <p>
      Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.<br />
      Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Du die
      folgende Datenschutzerklärung besser verstehst.
    </p>
    <h3>Was genau sind Cookies?</h3>
    <p>
      Immer wenn Du durch das Internet surfst, verwenden Du einen Browser. Bekannte Browser sind
      beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten
      Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
    </p>
    <p>
      Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle
      Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch
      andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien,
      die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden
      automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht.
      Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen
      zusätzlich ein oder mehrere Attribute angegeben werden.
    </p>
    <p>
      Cookies speichern gewisse Nutzerdaten von Dir, wie beispielsweise Sprache oder persönliche
      Seiteneinstellungen. Wenn Du unsere Seite wieder aufrufst, übermittelt dein Browser die
      „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website,
      wer Du bist und bietet Dir Deine gewohnte Standardeinstellung. In einigen Browsern hat jedes
      Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer
      einzigen Datei gespeichert.
    </p>
    <p>
      Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden
      direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten
      (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie
      andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis
      hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren,
      Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.
    </p>
    <p>
      So können zum Beispiel Cookie-Daten aussehen:
    </p>
    <ul>
      <li>Name: _ga</li>
      <li>Ablaufzeit: 2 Jahre</li>
      <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
      <li>Beispielhafter Wert: GA1.2.1326744211.152311140809</li>
    </ul>
    <p>Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
    <ul>
      <li>Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
      <li>Pro Domain sollen mindestens 50 Cookies gespeichert werden können</li>
      <li>Insgesamt sollen mindestens 3000 Cookies gespeichert werden können</li>
    </ul>
    <h3>Welche Arten von Cookies gibt es?</h3>
    <p>
      Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab
      und wird in der folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten
      wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
    </p>
    <p>Man kann 4 Arten von Cookies unterscheiden:</p>
    <p>
      <strong>Unbedingt notwendige Cookies<br /></strong>
      Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen.
      Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt,
      dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies
      wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
    </p>
    <p>
      <strong>Funktionelle Cookies<br /></strong>
      Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen
      bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei
      verschiedenen Browsern gemessen.
    </p>
    <p>
      <strong>Zielorientierte Cookies<br /></strong>
      Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
      Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.
    </p>
    <p>
      <strong>Werbe-Cookies<br /></strong>
      Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
      Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
    </p>
    <p>
      Üblicherweise wirst Du beim erstmaligen Besuch einer Webseite gefragt, welche dieser
      Cookiearten Du zulassen möchtest. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.
    </p>
    <h3>Wie kann ich Cookies löschen?</h3>
    <p>
      Wie und ob Du Cookies verwenden willst, entscheiden Du selbst. Unabhängig von welchem Service oder
      welcher Website die Cookies stammen, hast Du immer die Möglichkeit Cookies zu löschen, nur teilweise
      zuzulassen oder zu deaktivieren. Zum Beispiel kannst Du Cookies von Drittanbietern blockieren, aber
      alle anderen Cookies zulassen.
    </p>
    <p>
      Wenn Du feststellen möchtest, welche Cookies in Ihrem Browser gespeichert wurden, wenn Du Cookie-Einstellungen
      ändern oder löschen willst, kannst Du dies in Deinen Browser-Einstellungen finden:
    </p>
    <p>
      <a
        href="https:// support.google.com/chrome/answer/95647?tid=311140809"
        target="_blank"
        rel="noopener noreferrer">
        Chrome: Cookies in Chrome löschen, aktivieren und verwalten
      </a>
    </p>
    <p>
      <a
        href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311140809"
        target="_blank" rel="noopener noreferrer">
        Safari: Verwalten von Cookies und Websitedaten mit Safari
      </a>
    </p>
    <p>
      <a
        href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311140809"
        target="_blank" rel="noopener noreferrer">
        Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
      </a>
    </p>
    <p>
      <a
        href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311140809"
        target="_blank"
        rel="noopener noreferrer"
      >
        Internet Explorer: Löschen und Verwalten von Cookies
      </a>
    </p>
    <p>
      <a
        href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311140809"
        target="_blank" rel="noopener noreferrer">
        Microsoft Edge: Löschen und Verwalten von Cookies
      </a>
    </p>
    <p>
      Falls Du grundsätzlich keine Cookies haben willst, kannst Du Deinen Browser so einrichten,
      dass er Dich immer informiert, wenn ein Cookie gesetzt werden soll. So kannst Du bei jedem
      einzelnen Cookie entscheiden, ob Du das Cookie erlaubst oder nicht. Die Vorgangsweise ist je
      nach Browser verschieden. Am besten ist es Du suchen die Anleitung in Google mit dem Suchbegriff
      “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome Browsers
      oder tauschen das Wort &#8220;Chrome&#8221; gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.
    </p>
    <h3>Wie sieht es mit meinem Datenschutz aus?</h3>
    <p>
      Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von
      Cookies eine Einwilligung des Website-Besuchers (also von Dir) verlangt. Innerhalb der EU-Länder gibt
      es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland wurden die
      Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser
      Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
    </p>
    <p>
      Wenn Du mehr über Cookies wissen möchtst und vor technischen Dokumentationen nicht zurückscheust,
      empfehlen wir&nbsp;
      <a
        href="https://tools.ietf.org/html/rfc6265"
        target="_blank"
        rel="nofollow noopener noreferrer">
        https://tools.ietf.org/html/rfc6265
      </a>, dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management
            Mechanism&#8221;.
    </p>
    <h2>Rechte laut Datenschutzgrundverordnung</h2>
    <p>
      Dir stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
    <ul>
      <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
      <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
      <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
      <li>
        Recht auf Benachrichtigung &#8211; Mitteilungspflicht im Zusammenhang mit der Berichtigung oder
        Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
      </li>
      <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
      <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
      <li>
        Recht, nicht einer ausschließlich auf einer automatidurten Verarbeitung — einschließlich Profiling —
        beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
      </li>
    </ul>
    <p>&nbsp;</p>
    <h2>Eingebettete Social Media Elemente Datenschutzerklärung</h2>
    <p>
      Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder,
          Videos und Texte anzuzeigen.<br />
      Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Deinem Browser zum
      jeweiligen Social Media Dienst übertragen und dort gespeichert.
          Wir haben keinen Zugriff auf diese Daten.<br />
      Die folgenden Links führen dich zu den Seiten der jeweiligen Social Media Dienste wo erklärt wird,
          wie diese mit Deinen Daten umgehen:</p>
    <ul>
      <li>
        Instagram-Datenschutzrichtlinie:
        <a
          href="https://help.instagram.com/519522125107875?tid=311140809"
          target="_blank" rel="noopener noreferrer">
          https://help.instagram.com/519522125107875
        </a>
      </li>
      <li>
        Für YouTube gilt die Google Datenschutzerklärung:
        <a href="https://policies.google.com/privacy?hl=de&amp;tid=311140809"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
      </li>
      <li>
        Facebook-Datenrichtline:
        <a href="https://www.facebook.com/about/privacy?tid=311140809"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/about/privacy
        </a>
      </li>
      <li>
        Twitter Datenschutzrichtlinie:
        <a href="https://twitter.com/de/privacy?tid=311140809"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://twitter.com/de/privacy
        </a>
      </li>
    </ul>
    <h2>Instagram Datenschutzerklärung</h2>
    <p>
      Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media
      Plattform des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit
      2012 ein Tochterunternehmen von Facebook Inc. und gehört zu den Facebook-Produkten. Das Einbetten von
      Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch können wir Dir Inhalte wie Buttons,
      Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Du Webseiten unserer Webpräsenz
      aufrufst, die eine Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert
      und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. Deine Daten werden
      somit über alle Facebook-Firmen hinweg verarbeitet.
    </p>
    <p>
      Im Folgenden wollen wir Dir einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten
      es sich handelt und wie Du die Datenverarbeitung weitgehend kontrollieren kannst. Da Instagram zu Facebook
      Inc. gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits
      allerdings auch von den Facebook-Datenrichtlinien selbst.
    </p>
    <h3>Was ist Instagram?</h3>
    <p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit.
           Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie
           YouTube oder Vimeo. Du kannst auf „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und kurze
           Videos hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen Netzwerken verbreiten.
            Und wenn Du selbst nicht aktiv sein willst, kannst Du auch nur anderen interessante Users folgen.
    </p>
    <h3>Warum verwenden wir Instagram auf unserer Webseite?</h3>
    <p>
      Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging.
      Und natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Du dich auf unserer Webseite so wohl
      wie möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverständlich.
      Durch die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen, lustigen oder
      spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook
      ist, können uns die erhobenen Daten auch für personalidurte Werbung auf Facebook dienlich sein. So
      bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder Dienstleistungen
      interesduren.
    </p>
    <p>
      Instagram nutzt die gesammelten Daten auch zu Messungs- und Analysezwecken.
      Wir bekommen zusammengefasste Statistiken und so mehr Einblick über Deine Wünsche und Interessen.
          Wichtig ist zu erwähnen, dass diese Berichte dich nicht persönlich identifizieren.</p>
    <h3>Welche Daten werden von Instagram gespeichert?</h3>
    <p>
      Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram
      genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse.
      Wichtig zu erwähnen ist, dass diese Kundendaten erst an Instagram übermittelt werden, wenn Du zuvor „gehasht“
      wurdest. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten
      verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht
      Facebook – und folglich auch Instagram – Daten über Dein Userverhalten. Es kann auch vorkommen, dass
      Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten,
      die Instagram bereits von Dir hat abgeglichen.
    </p>
    <p>
      Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten
      an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Du selbst ein Instagram-Konto hast,
      werden unterschiedlich viele Daten gespeichert.
    </p>
    <p>
      Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook.
      Das bedeutet: wenn Du ein Instagram-Konto hast oder&nbsp;
      <a href="http://www.instagram.com?tid=311140809"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.instagram.com
      </a>&nbsp;
            besucht hast, hat Instagram zumindest ein Cookie gesetzt.
            Wenn das der Fall ist, sendet Dein Browser über das Cookie Infos an Instagram, sobald Du mit einer
            Instagram-Funktion in Berührung kommst. Spätestens nach 90 Tagen (nach Abgleichung) werden diese
            Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von
            Instagram beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt
            sammelt und speichert.
    </p>
    <p>
      Im Folgenden zeigen wir Dir Cookies, die in deinem Browser mindestens gesetzt werden, wenn Du auf eine
      Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus,
      dass Du kein Instagram-Konto hast. Wenn Du bei Instagram eingeloggt bist, werden natürlich deutlich mehr
      Cookies in Ihrem Browser gesetzt.
    </p>
    <p>
      Diese Cookies wurden bei unserem Test verwendet:</p>
    <p>
      <strong>Name: </strong>csrftoken<br />
      <strong>Wert: </strong>&#8220;&#8221;<br />
      <strong>Verwendungszweck: </strong>
      Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen
           von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.<br />
      <strong>Ablaufdatum:</strong> nach einem Jahr</p>
    <p>
      <strong>Name: </strong>mid<br />
      <strong>Wert: </strong>&#8220;&#8221;<br />
      <strong>Verwendungszweck: </strong>
      Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und Angebote in und außerhalb von
          Instagram zu optimieren. Das Cookie legt eine eindeutige User-ID fest.<br />
      <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
    <p>
      <strong>Name:</strong> fbsr_311140809124024<br />
      <strong>Wert: </strong>keine Angaben<br />
      <strong>Verwendungszweck: </strong>
      Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.<strong>
        <br />
      </strong>
      <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
    <p>
      <strong>Name:</strong> rur<br />
      <strong>Wert: </strong>ATN<br />
      <strong>Verwendungszweck: </strong>
      Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität auf Instagram gewährleistet.<br />
      <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
    <p>
      <strong>Name:</strong> urlgen<br />
      <strong>Wert: </strong>
      &#8220;&#123;\&#8221;194.96.75.33\&#8221;: 1901&#125;:1iEtYv:Y833k2_UjKvXgYe311140809&#8221;<br />
      <strong>Verwendungszweck: </strong>Dieses Cookie dient den Marketingzwecken von Instagram.<br />
      <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
    <p>
      <strong>Anmerkung:</strong> Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies
          im individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Deiner Verwendung
          von Instagram ab.
    </p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>
      Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern
      und mit Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung
      der eigenen Datenrichtlinie. Deine Daten sind, unter anderem aus Sicherheitsgründen, auf den
      Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.
    </p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>
      Dank der Datenschutz Grundverordnung haben Du das Recht auf Auskunft, Übertragbarkeit, Berichtigung
      und Löschung Deiner Daten. In den Instagram-Einstellungen kannst Du Deine Daten verwalten. Wenn Du Deine
      Daten auf Instagram völlig löschen willst, musst Du Dein Instagram-Konto dauerhaft löschen.
    </p>
    <p>
      Und so funktioniert die Löschung des Instagram-Kontos:</p>
    <p>
      Öffne zuerst die Instagram-App. Auf Deiner Profilseite gehst Du nach unten und klickst auf „Hilfebereich“.
      Jetzt kommst Du auf die Webseite des Unternehmens. Klicke auf der Webseite auf „Verwalten des Kontos“ und
          dann auf „Dein Konto löschen“.</p>
    <p>
      Wenn Du Dein Konto ganz löschst, löscht Instagram Posts wie beispielsweise Deine Fotos und Status-Updates.
      Informationen, die andere Personen über Dich geteilt haben, gehören nicht zu Ihrem Konto und werden folglich
      nicht gelöscht.
    </p>
    <p>
      Instagram ist ein Tochterunternehmen von Facebook Inc. und Facebook ist aktiver
      Teilnehmer beim EU-U.S. Privacy Shield Framework. Dieses Framework stellt eine korrekte
      Datenübertragung zwischen den USA und der Europäischen Union sicher. Unter&nbsp;
      <a
        href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC
      </a>&nbsp;
              erfährst Du mehr darüber. Wir haben versucht, Dir die wichtigsten Informationen über
              die Datenverarbeitung durch Instagram näherzubringen. Auf&nbsp;
      <a href="https://help.instagram.com/519522125107875"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://help.instagram.com/519522125107875
      </a>&nbsp;
      <br />
      kannst Du dich noch näher mit den
      Datenrichtlinien von Instagram auseinandersetzen.
    </p>
    <h2>YouTube Datenschutzerklärung</h2>
    <p>
      Wir haben auf unserer Webseite YouTube-Videos eingebaut. So können wir Dir interessante Videos direkt
      auf unserer Seite präsentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von
      Google LLC ist. Betrieben wird das Videoportal durch
      YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.
      Wenn Du auf unserer Webseite eine Seite aufrufst, die ein YouTube-Video eingebettet hat,
      verbindet sich Dein Browser automatisch mit den Servern von YouTube bzw. Google.
      Dabei werden (je nach Einstellungen) verschiedene Daten übertragen.
      Für die gesamte Datenverarbeitung ist Google verantwortlich und es gilt somit auch der Datenschutz von Google.
    </p>
    <p>
      Im Folgenden wollen wir Dir genauer erklären, welche Daten verarbeitet werden, warum wir YouTube-Videos
          eingebunden haben und wie Du Deine Daten verwalten oder löschen kannst.</p>
    <h3>Was ist YouTube?</h3>
    <p>
      Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen.
      Über die letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit.
      Damit wir Videos auf unserer Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung,
      den wir auf unserer Seite eingebaut haben.
    </p>
    <h3>Warum verwenden wir YouTube-Videos auf unserer Webseite?</h3>
    <p>
      YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content.
      Wir sind bemüht, Dir die bestmögliche User-Erfahrung auf unserer Webseite zu bieten.
      Und natürlich dürfen interessante Videos dabei nicht fehlen. Mithilfe unserer eingebetteten
      Videos stellen wir Dir neben unseren Texten und Bildern weiteren hilfreichen Content zur Verfügung.
      Zudem wird unsere Webseite auf der Google-Suchmaschine durch die eingebetteten Videos leichter gefunden.
      Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google – dank der gesammelten Daten –
      diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere Angebote interesduren.
    </p>
    <h3>Welche Daten werden von YouTube gespeichert?</h3>
    <p>
      Sobald Du eine unserer Seiten besuchst, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein
      Cookie, das Deine IP-Adresse und unsere URL speichert. Wenn Du in deinem YouTube-Konto eingeloggt bist,
      kann YouTube Deine Interaktionen auf unserer Webseite meist mithilfe von Cookies deinem Profil zuordnen.
      Dazu zählen Daten wie Sitzungsdauer, Absprungrate, ungefährer Standort, technische Informationen wie
      Browsertyp, Bildschirmauflösung oder Dein Internetanbieter. Weitere Daten können Kontaktdaten,
      etwaige Bewertungen, das Teilen von Inhalten über Social Media oder das Hinzufügen zu Deinen Favoriten
      auf YouTube sein.
    </p>
    <p>
      Wenn Du nicht in einem Google-Konto oder einem Youtube-Konto angemeldet bist, speichert Google Daten mit
      einer eindeutigen Kennung, die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise
      Deine bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten können nicht gespeichert werden,
      da weniger Cookies gesetzt werden.
    </p>
    <p>
      In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden.
      Wir zeigen einerseits Cookies, die ohne angemeldeten YouTube-Konto gesetzt werden. Andererseits zeigen wir
      Cookies, die mit angemeldetem Account gesetzt werden. Die Liste kann keinen Vollständigkeitsanspruch
      erheben, weil die Userdaten immer von den Interaktionen auf YouTube abhängen.
    </p>
    <p>
      <strong>Name:</strong> YSC<br />
      <strong>Wert:</strong> b9-CV6ojI5Y<br />
      <strong>Verwendungszweck:</strong>
      Dieses Cookie registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu speichern.<br />
      <strong>Ablaufdatum:</strong> nach Sitzungsende</p>
    <p>
      <strong>Name:</strong> PREF<br />
      <strong>Wert:</strong> f1=50000000<br />
      <strong>Verwendungszweck:</strong>
      Dieses Cookie registriert ebenfalls Deine eindeutige ID. Google bekommt über PREF Statistiken,
           wie Du YouTube-Videos auf unserer Webseite verwendest.<br />
      <strong>Ablaufdatum:</strong> nach 8 Monate</p>
    <p>
      <strong>Name:</strong> GPS<br />
      <strong>Wert:</strong> 1<br />
      <strong>Verwendungszweck:</strong>
      Dieses Cookie registriert Deine eindeutige ID auf mobilen Geräten, um den GPS-Standort zu tracken.<br />
      <strong>Ablaufdatum:</strong> nach 30 Minuten</p>
    <p>
      <strong>Name:</strong> VISITOR_INFO1_LIVE<br />
      <strong>Wert:</strong> 95Chz8bagyU<br />
      <strong>Verwendungszweck:</strong>
      Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem YouTube-Video)
          zu schätzen. <br />
      <strong>Ablaufdatum:</strong> nach 8 Monaten</p>
    <p>Weitere Cookies, die gesetzt werden, wenn Du mit deinem YouTube-Konto angemeldet bist:</p>
    <p>
      <strong>Name:</strong> APISID<br />
      <strong>Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7311140809-<br />
      <strong>Verwendungszweck:</strong>
      Dieses Cookie wird verwendet, um ein Profil über Deine Interessen zu erstellen.
          Genützt werden die Daten für personalidurte Werbeanzeigen.<br />
      <strong>Ablaufdatum:</strong> nach 2 Jahre</p>
    <p>
      <strong>Name:</strong> CONSENT<br />
      <strong>Wert:</strong> YES+AT.de+20150628-20-0<br />
      <strong>Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines
          Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um
          User zu überprüfen und Userdaten vor unbefugten Angriffen zu schützen.<br />
      <strong>Ablaufdatum: </strong>nach 19 Jahren</p>
    <p>
      <strong>Name:</strong> HSID<br />
      <strong>Wert:</strong> AcRwpgUik9Dveht0I<br />
      <strong>Verwendungszweck:</strong>
      Dieses Cookie wird verwendet, um ein Profil über Deine Interessen zu erstellen. Diese Daten helfen
          personalidurte Werbung anzeigen zu können.<br />
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong>Name:</strong> LOGIN_INFO<br />
      <strong>Wert:</strong> AFmmF2swRQIhALl6aL…<br />
      <strong>Verwendungszweck:</strong>
      In diesem Cookie werden Informationen über Deine Login-Daten gespeichert.<br />
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong>Name:</strong> SAPISID<br />
      <strong>Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br />
      <strong>Verwendungszweck:</strong>
      Dieses Cookie funktioniert, indem es Deinen Browser und Dein Gerät eindeutig identifiziert.
           Es wird verwendet, um ein Profil über Deine Interessen zu erstellen.<br />
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong>Name:</strong> SID<br />
      <strong>Wert:</strong> oQfNKjAsI311140809-<br />
      <strong>Verwendungszweck:</strong>
      Dieses Cookie speichert Deine Google-Konto-ID und Deinen letzten Anmeldezeitpunkt in digital signierter
          und verschlüsselter Form.<br />
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong>Name:</strong> SIDCC<br />
      <strong>Wert:</strong> AN0-TYuqub2JOcDTyL<br />
      <strong>Verwendungszweck:</strong>
      Dieses Cookie speichert Informationen, wie Du die Webseite nutzt und welche Werbung Du vor dem Besuch auf
           unserer Seite möglicherweise gesehen hast.<br />
      <strong>Ablaufdatum:</strong> nach 3 Monaten
    </p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>
      Die Daten, die YouTube von Dir erhält und verarbeitet werden auf den Google-Servern gespeichert.
      Die meisten dieser Server befinden sich in Amerika. Unter&nbsp;
      <a href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.google.com/about/datacenters/inside/locations/?hl=de
      </a>&nbsp;
            siehst Du genau wo sich die Google-Rechenzentren befinden. Deine Daten sind auf den Servern verteilt.
             So sind die Daten schneller abrufbar und vor Manipulation besser geschützt.
    </p>
    <p>
      Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten kannst Du jederzeit löschen,
      andere werden automatisch nach einer begrenzten Zeit gelöscht und wieder andere werden von Google über
      längere Zeit gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos oder Dokumente, Produkte),
      die in Ihrem Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Du sie löschst. Auch wenn Du
      nicht in einem Google-Konto angemeldet bist, kannst Du einige Daten, die mit Ihrem Gerät, Browser oder App
          verknüpft sind, löschen.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>
      Grundsätzlich können Du Daten im Google Konto manuell löschen. Mit der 2019 eingeführten automatische
      Löschfunktion von Standort- und Aktivitätsdaten werden Informationen abhängig von Deiner Entscheidung
      &#8211; entweder 3 oder 18 Monate gespeichert und dann gelöscht.
    </p>

    <h2>Spotify Datenschutzerklärung</h2>
    <p>
    Auf dieser Website sind Funktionen des Musik-Dienstes Spotify eingebunden. Anbieter ist die Spotify AB,
Birger Jarlsgatan 61, 113 56 Stockholm in Schweden. Die Spotify Plugins erkennen Sie an dem grünen Logo
auf dieser Website. Eine Übersicht über die Spotify-Plugins finden Sie unter:
      <a href="https://developer.spotify.com" target="_blank" rel="noopener noreferrer">https://developer.spotify.com</a>.
Dadurch kann beim Besuch dieser Website über das Plugin eine direkte Verbindung zwischen Ihrem
Browser und dem Spotify-Server hergestellt werden. Spotify erhält dadurch die Information, dass Sie mit
Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den Spotify Button anklicken, während Sie in
Ihrem Spotify-Account eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem Spotify Profil
verlinken. Dadurch kann Spotify den Besuch dieser Website Ihrem Benutzerkonto zuordnen.
Wir weisen darauf hin, dass bei der Nutzung von Spotify Cookies von Google Analytics eingesetzt werden,
sodass Ihre Nutzungsdaten bei der Nutzung von Spotify auch an Google weitergegeben werden können.
Google Analytics ist ein Tool des Google-Konzerns zur Analyse des Nutzerverhaltens mit Sitz in den USA.
Für diese Einbindung ist allein Spotify verantwortlich. Wir als Websitebetreiber haben auf diese
Verarbeitung keinen Einfluss.<br/>
Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
Websitebetreiber hat ein berechtigtes Interesse an der ansprechenden akustischen Ausgestaltung seiner
Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung
von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Spotify:
 6 / 8
      <a href="https://www.spotify.com/de/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">https://www.spotify.com/de/legal/privacy-policy/.</a>
Wenn Sie nicht wünschen, dass Spotify den Besuch dieser Website Ihrem Spotify-Nutzerkonto zuordnen
kann, loggen Sie sich bitte aus Ihrem Spotify-Benutzerkonto aus.
    </p>
  </React.Fragment>
}

const combinedDatenschutz = () => {
  return <React.Fragment>
    <ContextProvider>
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="content-language" content="de" />
        <meta name="description" content="Datenschutzbestimmung" />
        <meta name="robots" content="noindex, follow" />
        <meta name="twitter:card" content="summary" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:description" content="Datenschutzbestimmung" />
        <meta property="og:image" content="https://www.stone-cold-love.de/images/logo.jpg" />
        <meta property="og:image:type" content="image/jpeg"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="1200"/>
        <meta property="og:image:alt" content="Stone Cold Love Band Logo - rote Lippen/Mund mit Zähnen"></meta>
        <meta property="og:title" content="Datenschutzbestimmung der Band STONE COLD LOVE" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.stone-cold-love.de/datenschutz/"></meta>
        <meta property="twitter:description" content="Datenschutzbestimmung" />
        <meta property="twitter:image:src" content="https://www.stone-cold-love.de/images/logo.jpg" />
        <meta property="twitter:image:alt" content="Stone Cold Love Band Logo - rote Lippen/Mund mit Zähnen"></meta>
        <meta property="twitter:site" content="https://www.stone-cold-love.de/datenschutz/"></meta>
        <meta property="twitter:title" content="Datenschutzbestimmung der Band STONE COLD LOVE" />
        <title>Datenschutzbestimmung der Band STONE COLD LOVE</title>
        <meta name="keywords" content="STONE, COLD, LOVE, BAND, ROCK, MUSIK, DATENSCHUTZ" />
      </Head>
      <Start />
      <div className="pageWrapper">
        <h1>Datenschutzerklärung</h1>
        <h2>Datenschutz</h2>
        <Datenschutz />
      </div>
    </ContextProvider>
  </React.Fragment>
}

export default withSiteData(combinedDatenschutz)
