import React from 'react'
import PropTypes, { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import './index.scss'
import classNames from 'classnames'
import Context from '../../context'
import CookieIcon from './cookie.svg'

export class CookiesHeader extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.onClose = this.onClose.bind(this)
    this.onCloseAccept = this.onCloseAccept.bind(this)
    this.linkURL = '/datenschutz'
    this.state = {
      hideCookieBanner: true
    }
  }

  componentDidMount() {
    this.setState(() => ({
      hideCookieBanner: !!this.props.cookies.get('cookies-accepted') || this.context.cookiesAccepted || false
    }), () => {
      if (this.state.hideCookieBanner) {
        this.context.setValue({ cookiesAccepted: true })
      }
    })
  }

  onClose() {
    this.setState(() => ({
      hideCookieBanner: true
    }))
  }

  onCloseAccept() {
    const { cookies } = this.props
    cookies.set('cookies-accepted', 'true', { path: '/' })
    this.onClose()
    this.context.setValue({ cookiesAccepted: true })
  }

  render() {
    const cookiesClasses = classNames(
      'CookiesBannerWrapper',
      this.props.className,
      { hidden: this.state.hideCookieBanner },
      { CookiesBannerWrapperAlternative: this.props.alternative }
    )
    return (
      <div className={cookiesClasses}>
        <div className="CookiesBanner">
          {!this.props.alternative && <div className="CookiesBannerInfoIcon" />}
          {
            this.props.alternative &&
            <img src={CookieIcon} className="cookieIcon" alt="cookie icon" />
          }
          <div className="CookiesBannerWarning">
            { !this.props.alternative ? <>Diese Webseite verwendet Cookies, z.B. um Inhalte zu personalisieren.
            Möchtest du nur die notwendigsten Inhalte laden scroll einfach weiter oder klick auf das x,
            möchtest du alles sehen, bestätige mit Klick auf das Häkchen. </>
              : <>An dieser Stelle werden Inhalte blockiert, da bisher keine Cookies akzeptiert wurden.
              Möchtest du die Inhalte nachladen und Cookies erlauben? Klicke auf das Häkchen. </>
            }
           Weitere Informationen findest du in der
            <a href={this.linkURL}> Datenschutzerklärung</a>
            
          </div>
          {
            !this.props.alternative ?
              <div className="CookiesBannerClose" onClick={this.onClose}></div>
              : null
          }
          <div className="CookiesBannerTick" onClick={this.onCloseAccept}></div>
        </div>
      </div>
    )
  }
}

CookiesHeader.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
  className: PropTypes.string,
  alternative: PropTypes.bool
}

CookiesHeader.contextType = Context

export default withCookies(CookiesHeader)
