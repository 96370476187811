import React, { useEffect, useState } from 'react'
import { withSiteData } from 'react-static'
import Start from './../componets/Startanimation'
import './../styles/pages/intern.scss'
import ContextProvider from './../componets/ContextProvider'

export default withSiteData(() => {
  const [code, setCode] = useState(false)
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const codeQuerry = urlParams.get('code')
    setCode(codeQuerry)
  })
  return <ContextProvider>
    <div className="internContend">
      <Start/>
      <div className="pageWrapper">
        {!code
          ?
          <a className="internContendLink" href="https://api.instagram.com/oauth/authorize?client_id=447908356133124&redirect_uri=https://intern.stone-cold-love.de/insta-auth.php&scope=user_profile,user_media&response_type=code">{'>>> Login <<<'}</a>
          :
          <a href="/intern">Log out</a>
        }
      </div>
    </div>
  </ContextProvider>
})
