import React from 'react'
import { Head, withSiteData, withRouteData } from 'react-static'
import Start from './../componets/Startanimation'
import './../styles/pages/band.scss'
import ContextProvider from './../componets/ContextProvider'
import WebPicture from './../componets/WebPicture'
import { isElementInViewport } from './../utils/utils'
import classNames from 'classnames'
import { withController } from 'react-scroll-parallax'
import PropTypes from 'prop-types'
import metaHeader from './../componets/MetaHeader'
import ReactMarkdown from 'react-markdown'

class BandClass extends React.Component {
  constructor() {
    super()
    this.state = {
      bandIsVisible: false,
      bandpicCouldBeAnimated: true,
      turningPoint: 0,
      showBandpic: false,
      showBandText: false,
      bandpic: '/images/band01.png',
      srcSet: [
        {
          imgType: 'png',
          mediaQuery: '420'
        },
        {
          imgType: 'webp'
        },
        {
          imgType: 'webp',
          mediaQuery: '420'
        }
      ]
    }
    this.onScroll = this.onScroll.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
  }
  handleLoad() {
    this.props.parallaxController.update()
  }
  onScroll() {
    const currentScrollPosY = Math.round(window.scrollY)
    const offset = 250
    const scale = 40
    const {
      bandpicCouldBeAnimated, turningPoint
    } = this.state
    let {
      bandIsVisible
    } = this.state

    bandIsVisible = isElementInViewport(document.getElementsByClassName('bandhead')[0])
    if (bandpicCouldBeAnimated || currentScrollPosY <= turningPoint) {
      this.setState({ bandpicCouldBeAnimated: true })
      if (bandIsVisible) {
        this.setState({ showBandpic: true, bandpicCouldBeAnimated: false, turningPoint: currentScrollPosY })
      } else {
        this.setState({ showBandpic: false })
      }
    }
    // TODO: replace with loop
    if (currentScrollPosY > turningPoint
      && currentScrollPosY < turningPoint + offset) {
      this.setState({ bandpic: '/images/band01.png' })
    }
    if (currentScrollPosY > turningPoint + offset
      && currentScrollPosY < turningPoint + offset + scale) {
      this.setState({ bandpic: '/images/band02.png' })
    }
    if (currentScrollPosY > turningPoint + offset + scale
      && currentScrollPosY < turningPoint + offset + (2 * scale)) {
      this.setState({ bandpic: '/images/band03.png' })
    }
    if (currentScrollPosY > turningPoint + offset + (2 * scale)
      && currentScrollPosY < turningPoint + offset + (3 * scale)) {
      this.setState({ bandpic: '/images/band04.png' })
    }
    if (currentScrollPosY > turningPoint + offset + (3 * scale)
      && currentScrollPosY < turningPoint + offset + (4 * scale)) {
      this.setState({ bandpic: '/images/band05.png' })
    }
    if (currentScrollPosY > turningPoint + offset + (4 * scale)
      && currentScrollPosY < turningPoint + offset + (5 * scale)) {
      this.setState({ bandpic: '/images/band06.png' })
    }
    if (currentScrollPosY > turningPoint + offset + (5 * scale)
      && currentScrollPosY < turningPoint + offset + (6 * scale)) {
      this.setState({ bandpic: '/images/band07.png' })
    }
    if (currentScrollPosY > turningPoint + offset + (6 * scale)
      && currentScrollPosY < turningPoint + offset + (7 * scale)) {
      this.setState({ bandpic: '/images/band08.png' })
    }
    if (currentScrollPosY > turningPoint + offset + (7 * scale)
      && currentScrollPosY < turningPoint + offset + (8 * scale)) {
      this.setState({ bandpic: '/images/band09.png', showBandText: true })
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      window.addEventListener('scroll', this.onScroll, false)
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onScroll, false)
    }
  }

  render() {
    const bandpicClasses = classNames(
      'bandpic',
      { hidden: !this.state.showBandpic }
    )
    const bandTextClasses = classNames(
      'bandText',
      { hidden: !this.state.showBandpic }
    )
    const preloder = []
    for (let index = 1; index < 10; index++) {
      preloder.push(
        <WebPicture
          key={index}
          width={0}
          aria-hidden="true"
          height={0}
          className="hidden"
          src={`/images/band0${index}.png`}
          srcSets={this.state.srcSet}
          alt={`Puzzelteil ${index} - Bandfoto - Stone Cold Love ` +
      `- 5 Personen im Schatten - Band im stilvoll beleuchteten Gang - preload Image ${index}`}/>)
    }
    const { bandData } = this.props
    return <div className="pageWrapper" >
      <h1 className="bandhead text-center">{bandData.title}</h1>
      <div className="bandContend">
        {preloder}
        <WebPicture
          onLoad={() => {
            this.handleLoad()
          }}
          className={bandpicClasses}
          alt={'komplettes Bandfoto - Stone Cold Love - 5 Personen im Schatten - Band im stilvoll beleuchteten Gang'}
          src={this.state.bandpic}
          srcSets={this.state.srcSet}
        />
        <div className={bandTextClasses}><ReactMarkdown>{bandData.text}</ReactMarkdown></div>
      </div>
    </div>
  }
}

BandClass.propTypes = {
  parallaxController: PropTypes.object,
  bandData: PropTypes.object
}

export const Band = withRouteData(withSiteData(withController(BandClass)))

const combinedBand = (props) => {
  const { bandData } = props
  return <React.Fragment>
    <ContextProvider>
      <Head>
        {metaHeader(bandData)}
      </Head>
      <Start />
      <Band />
    </ContextProvider>
  </React.Fragment>
}

export default withRouteData(combinedBand)
