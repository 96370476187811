import React, { useState } from 'react'
import { withSiteData } from 'react-static'

export default withSiteData(() => {
  const [list1, setList1] = useState('')
  const [list2, setList2] = useState('')
  const [list3, setList3] = useState([])
  const [list4, setList4] = useState([])
  const [list5, setList5] = useState([])

  const onlyUnique = (value, index, selfArray) => {
    return selfArray.indexOf(value) === index
  }

  const returnUserList = (list) => {
    const match = list.match(/href="([^"]*)/g)
    const cleanUser = []
    match.map((user) => {
      let tempUser = user.replace('href="/', '')
      tempUser = tempUser.slice(0, tempUser.length - 1)
      cleanUser.push(tempUser)
    })
    return cleanUser.filter(onlyUnique)
  }

  const handleSubmit = (eve) => {
    eve.preventDefault()
    const cleanUsers1 = returnUserList(list1)
    const cleanUsers2 = returnUserList(list2)
    setList3(cleanUsers1)
    setList4(cleanUsers2)
    const doesNotFollowUs = []
    cleanUsers2.map((currentUser) => {
      if (!cleanUsers1.includes(currentUser)) {
        doesNotFollowUs.push(currentUser)
      }
    })
    setList5(doesNotFollowUs)
  }
  const listItems = list5.map((number, key) =>
    <li key={key}><a href={`https://www.instagram.com/${number}/`}>{`https://www.instagram.com/${number}/`}</a></li>
  )
  return <form>
    <h1>Who follow us? </h1>
    <label>Abonnenten:
      <textarea
        type="text"
        rows="6"
        value={list1}
        onChange={(e) => setList1(e.target.value)}
      />
    </label>
    <label>abonniert:
      <textarea
        type="text"
        rows="6"
        value={list2}
        onChange={(e) => setList2(e.target.value)}
      />
    </label>
    <br/>
    <h2>clean:</h2>
    <label>Abonnenten:
      <textarea
        type="text"
        disabled
        rows="6"
        value={list3}
      />
    </label>
    <label>abonniert:
      <textarea
        type="text"
        disabled
        rows="6"
        value={list4}
      />
    </label>
    <br/>
    <h2>result:</h2>
    <label>Does not follow us:
      {listItems}
    </label>
    <br/>
    <button onClick={handleSubmit}>Vergleichen</button>
  </form>
})
