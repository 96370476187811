import React from 'react'
import PropTypes from 'prop-types'
import { AppProvider } from './../context'

class ContextProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      setValue: async(newValue) => {
        this.setState(newValue)
      }
    }
  }

  render() {
    return <AppProvider value={this.state}>{this.props.children}</AppProvider>
  }
}

ContextProvider.propTypes = {
  children: PropTypes.node
}

export default ContextProvider
